<template>
  <div>
    <div class="-content d-flex align-items-start flex-column">
      <div class="page-global-header px-0">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 offset-xl-3 offset-xxl-3">
            <div class="global-searchbar">
              <div class="base-form">
                <div class="input-group">
                  <div class="input-group-text">
                    <img src="@/assets/images/system/groups.svg" />
                  </div>
                  <input type="search" name="globalSearch" class="form-control"
                    placeholder="Search (Vendor name, expense number etc)" v-model="search.search_text" />
                  <button @click="getData(1)" name="btnSearch" class="btn btn-primary">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-3 col-xxl-3 d-flex align-items-center justify-content-end">
            <ul class="search-option-menu me-3">
              <li class="nav-item sorting">
                <input type="checkbox" class="sorting-check" v-model="expense_sorting_desc" @change="changeOrder"
                  id="btn-sorting" autocomplete="off" />
                <label class="btn btn-sorting" for="btn-sorting"><span class="sr-only">Sort</span>
                </label>
              </li>
              <li class="nav-item">
                <a href="/add-expense" class="nav-link circleNav"><i class="fa fa-plus"></i>
                </a>
              </li>
              <li class="nav-item dropdown">
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <i class="fas fa-ellipsis-v"></i>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item @click="generatePdf">PDF &nbsp;<i
                          class="fa fa-envelope append"></i></el-dropdown-item>
                      <el-dropdown-item @click="generateExcel">CSV &nbsp;<i
                          class="fa fa-print append"></i></el-dropdown-item>
                      <el-dropdown-item @click="generateZip">Download Zip &nbsp;<i
                          class="fa fa-download append"></i></el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </li>
            </ul>
          </div>
          <div class="clearfix"></div>

          <div class="row mt-2 text-center">
            <div class="col-3">
              <el-date-picker v-model="search.date" type="daterange" class="me-2" unlink-panels range-separator="To"
                start-placeholder="Start month" end-placeholder="End month" size="large" format="YYYY-MM-DD"
                value-format="YYYY-MM-DD" @change="getData(1)" />
            </div>
            <div class="col-3">
              <el-select filterable clearable class="me-2" v-model="search.category" placeholder="Category" size="large"
                @change="getData(1)">
                <el-option v-for="item in expenseCategoryServer" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </div>
            <div class="col-3">
              <el-select filterable class="me-2" v-model="search.currency" placeholder="Currency" size="large"
                @change="getData(1)">
                <el-option v-for="item in currencyOptions" :key="item.id"
                  :label="item.currency + ' (' + item.symbol + ')'" :value="item.id" />
              </el-select>
            </div>

            <div class="col-3">
              <el-select filterable clearable class="me-2" v-model="search.payment_method" placeholder="Payment Method"
                size="large" @change="getData(1)">
                <el-option v-for="item in payment_method" :key="item" :label="item" :value="item" />
              </el-select>
            </div>
          </div>
        </div>
      </div>

      <div class="page-body expenses-list-body">
        <div class="mt-3">
          <div class="row justify-content-center">
            <!--repeatable block-->
            <div class="col-sm-7 expenses-list-single" v-for="(expense, key) in expensesData" :key="'expense' + key">
              <div class="card card-horizontal-list bostonBlue">
                <div class="card-body">
                  <div class="hover-slide">
                    <div class="form-check outliner">
                      <input class="form-check-input" type="checkbox" value="" />
                    </div>
                  </div>
                  <div class="card-content">
                    <span class="card-count">{{ (key += 1) }}. </span>
                    <ul class="label-list">
                      <li class="list-title">Ex.No: {{ expense.expense_no }}</li>
                      <li class="sub-title">C: {{ expense.category }}</li>
                      <li class="text-danger">D: {{ expense.expense_date }}</li>
                      <li>V: {{ expense.vendor }}</li>
                    </ul>
                  </div>
                  <div class="content-option w-auto">
                    <div class="row m-0 h-100 justify-content-between flex-column">
                      <ul class="card-action-nav h-auto">
                        <li class="nav-item">
                          <a href="" class="btn btn-success btn-sm-x">{{
                            expense.payment_type
                          }}</a>
                        </li>
                      </ul>
                      <ul class="card-action-nav h-auto flex-row flex-nowrap">
                        <li class="nav-item">
                          <template v-if="expense.file_name != 'N/A'">
                            <a href="#" class="btn btn-primary btn-sm" data-bs-toggle="modal"
                              data-bs-target="#expenseFile" @click="fileExpensesDetails(expense.id)"><i
                                class="fa fa-paperclip"></i> File
                            </a>
                          </template>
                        </li>
                        <li class="nav-item ms-2">
                          <template v-if="expense.notes != null">
                            <a href="#" class="btn btn-primary btn-sm" data-bs-toggle="modal"
                              data-bs-target="#expenseNote" @click="fileExpensesDetails(expense.id)"><i
                                class="fa fa-edit"></i> Note
                            </a>
                          </template>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="card-footer has-action flex-shrink-1">
                  <div class="ellipsis-dropdown dropdown">
                    <el-dropdown>
                      <span class="el-dropdown-link">
                        <i class="fas fa-ellipsis-v text-white"></i>
                      </span>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item @click="showEditData(expense.id)"><i
                              class="fa fa-edit"></i></el-dropdown-item>
                          <el-dropdown-item @click="deleteData(expense.id)"><i
                              class="fa fa-times text-danger"></i></el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </div>
                  <div class="footer-list-wraper justify-content-end">
                    <ul class="footer-list _big">
                      <li>Amount</li>
                      <li class="count">
                        {{ getCurrencyName(expense.currency_id) }}
                        {{ parseFloat(expense.amount).toFixed(2) }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mx-0 justify-content-center">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 col-xxl-4">
                <div class="row m-0 mb-3 mt-5 d-flex">
                  <div class="input-group input-group--3d group-half p-0">
                    <div class="input-group-text bg-orange no-after prepend">
                      Amount Paid
                    </div>
                    <div class="form-floating">
                      <label class="floating-label">{{
                        getCurrencyName(search.currency)
                      }}</label>
                      <input class="form-control text-right" type="text" :value="expensesData.reduce((total, expense) => {
                        // Convert the amount to a number before adding
                        return total + parseFloat(expense.amount);
                      }, 0).toFixed(2)
                        " placeholder="10,000.00" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--ended repeatable block-->
          </div>
          <!-- pagination  -->
          <div v-if="pagination" v-show="pagination.last_page > 1">
            <ul class="pagination">
              <li class="page-item" v-if="pagination.current_page > 1" @click="getData(pagination.current_page - 1)">
                <a class="page-link" href="javascript:;">Previous</a>
              </li>
              <li class="page-item" v-for="page in pagination.last_page" :key="page"
                :class="{ active: page === pagination.current_page }" @click="getData(page)">
                <a class="page-link" href="javascript:;">{{ page }}</a>
              </li>
              <li class="page-item" v-if="pagination.current_page < pagination.last_page"
                @click="getData(pagination.current_page + 1)">
                <a class="page-link" href="javascript:;">Next</a>
              </li>
            </ul>
          </div>
          <!-- pagination end -->
        </div>

        <div class="row mx-0 mt-5" v-if="show_total_expense">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 offset-xl-3 offset-xxl-3">
            <div class="px-5 d-flex justify-content-end">
              <div class="col-sm-12 col-md-12 col-lg-7">
                <div class="input-group input-group--3d group-half p-0">
                  <div class="input-group-text bg-orange no-after prepend">
                    Amount Paid
                  </div>
                  <div class="form-floating">
                    <label class="floating-label">&pound;</label>
                    <input class="form-control text-right" type="text" placeholder="10,000.00" value="10,000.00" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div
                        class="col-sm-12 col-md-12 col-lg-12 col-xl-3 col-xxl-3 d-flex align-items-center justify-content-center">
                        <button type="button" name="btn" class="btn btn-offWhite">Close</button>
                    </div> -->
        </div>
      </div>
    </div>

    <!--Expense File Preview Modal file-->
    <div class="modal fade mdl-messaging _info" id="expenseFile" aria-hidden="true" aria-labelledby="expenseFileLabel"
      tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-dialog-header">
          <div class="header-title">Expense preview</div>
        </div>
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <i class="fa fa-times"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <div class="message-card no-shadow m-0">
              <div class="abs-nav abs--tr me-n5">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <a class="nav-link" href="#" data-bs-dismiss="modal" @click="showEditData(expenses_preview.id)"><i
                        class="fa fa-edit"></i>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#"><i class="fa fa-print"></i> </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#"><i class="fa fa-envelope"></i> </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" @click="deleteData(expense.id)"><i class="fa fa-download"></i>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link"><i class="fas fa-ban"></i> </a>
                  </li>
                </ul>
              </div>

              <div class="w-100">
                <ul class="list-group list-group--borderless list-group--compact">
                  <li class="list-group-item">
                    Expense number: {{ expenses_preview.expense_number }}
                  </li>
                  <li class="list-group-item">
                    Category: {{ expenses_preview.category }}
                  </li>
                  <li class="list-group-item">
                    Vendor name: {{ expenses_preview.vendor }}
                  </li>
                  <li class="list-group-item">Date: {{ expenses_preview.date }}</li>
                  <li class="list-group-item d-flex justify-content-between">
                    <span>Payment method: {{ expenses_preview.payment_method }}</span>
                    <span>Amount:
                      {{
                        expenses_preview.currency_id
                          ? getCurrencyName(expenses_preview.currency_id)
                          : ""
                      }}
                      {{ expenses_preview.amount }}</span>
                  </li>
                </ul>
              </div>

              <div class="card card--curve-05rem mt-3 w-100 align-items-center justify-content-center">
                <div class="w-auto p-2">
                  <span v-for="(url_image, key) in expenses_preview.image_url" v-bind:key="key">
                    <img :src="url_image" class="img-fluid mb-2" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Expense File Preview Modal note-->
    <div class="modal fade mdl-messaging _info" id="expenseNote" aria-hidden="true" aria-labelledby="expenseNoteLabel"
      tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-dialog-header">
          <div class="header-title">Expense preview</div>
        </div>
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <i class="fa fa-times"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <div class="message-card no-shadow m-0">
              <div class="abs-nav abs--tr me-n5">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <a class="nav-link" href="#" @click="showEditData(expenses_preview.id)"><i class="fa fa-edit"></i>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#"><i class="fa fa-print"></i> </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#"><i class="fa fa-envelope"></i> </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link"><i class="fa fa-download"></i> </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link"><i class="fas fa-ban"></i> </a>
                  </li>
                </ul>
              </div>

              <div class="w-100">
                <ul class="list-group list-group--borderless list-group--compact">
                  <li class="list-group-item">
                    Expense number: {{ expenses_preview.expense_number }}
                  </li>
                  <li class="list-group-item">
                    Category: {{ expenses_preview.category }}
                  </li>
                  <li class="list-group-item">
                    Vendor name: {{ expenses_preview.vendor }}
                  </li>
                  <li class="list-group-item">Date: {{ expenses_preview.date }}</li>
                  <li class="list-group-item d-flex justify-content-between">
                    <span>Payment method: {{ expenses_preview.payment_method }}</span>
                    <span>Amount:
                      {{
                        expenses_preview.currency_id
                          ? getCurrencyName(expenses_preview.currency_id)
                          : ""
                      }}
                      {{ expenses_preview.amount }}</span>
                  </li>
                </ul>
              </div>

              <template v-if="expenses_preview.note != null">
                <div class="card card--curve-05rem mt-3 w-100">
                  <div class="card-body">
                    <p class="m-0">Note:</p>
                    <p class="m-0">
                      {{ expenses_preview.note }}
                    </p>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
Date.prototype.addMonths = function (months) {
  var date = new Date(this.valueOf());
  date.setMonth(date.getMonth() + months);
  return date;
};

export default {
  name: "ExpenseComponent",
  data() {
    return {
      loadingInstance: null,
      isLoading: false,
      loadingText: "Loading...",
      show_total_expense: 0,
      expenses_server_data: [],
      expenses_server_data_unfiltred: [],
      expense_category: null,
      currency: null,
      payment_method: null,
      expenses_preview: {
        id: "",
        expense_number: "",
        category: "",
        vendor: "",
        date: "",
        payment_method: "",
        note: "",
        currency_id: "",
        amount: "",
        image_url: [],
      },
      search: {
        date: [
          new Date(new Date().getFullYear(), new Date().getMonth() - 5, 1)
            .toISOString()
            .slice(0, 10),
          new Date().toISOString().slice(0, 10),
        ],
        category: "",
        currency: this.$store.state.auth.user.currency_id,
        payment_method: "",
        search_text: "",
      },
      file_data: "",
      file_popup: false,
      note_popup: false,
      expense_category_filter: [],
      currency_filter: [],
      payment_filter: [],
      pagination: {},
      expense_sorting_desc: false,
    };
  },
  mounted() {
    this.isProfileComplete = this.$store.getters["auth/getProfileCompleteStatus"];

    if (!this.isProfileComplete) {
      this.$router.push({ name: "dashboard" }); // Replace 'dashboard' with your actual route name
    }

    this.showLoading();
    // Use Promise.all to wait for both showLoading and getDropdowns to finish
    Promise.all([this.getDropdowns()]).then(() => {
      this.getData(1);
    });
  },
  computed: {
    expensesData() {
      return this.expenses_server_data;
    },
    expenseCategoryServer() {
      if (this.expense_category) {
        return this.expense_category.map((v) => {
          return {
            id: v.id,
            name: v.category,
          };
        });
      }
      return [];
    },
    currencyOptions() {
      if (this.currency) {
        return this.currency.map((v) => {
          return {
            id: v.id,
            currency: v.currency,
            symbol: v.symbol,
          };
        });
      }
      return [];
    },
  },
  methods: {
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
        fullscreen: true,
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    async getDropdowns() {
      try {
        await this.fetchCurrency();
        await this.fetchPaymentMethod();
        await this.expenseCategory();
      } catch (error) {
        console.log(error);
        // this.$toast.error("Something went wrong. Please try again.");
      }
    },
    async expenseCategory() {
      this.expense_category = await this.$store
        .dispatch("expenses/getExpenseCategory")
        .then((res) => {
          if (res.status == 1) {
            return res.response;
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => { });
    },
    async fetchCurrency() {
      this.currency = await this.$store
        .dispatch("expenses/getUsersCurrency")
        .then((res) => {
          if (res.status == 1) {
            return res.response;
            // this.$router.push({ name: 'user-dashboard' });
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => { });
    },
    async fetchPaymentMethod() {
      this.payment_method = await this.$store
        .dispatch("expenses/getPaymentOptions")
        .then((res) => {
          // console.log(res);
          if (res.status == 1) {
            return res.response;
            // this.$router.push({ name: 'user-dashboard' });
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => { });
    },
    async getData(pageNumber = 1) {
      this.showLoading();
      const data = {
        page: pageNumber,
        from: this.search.date[0],
        to: this.search.date[1],
        category_id: this.search.category,
        currency_id: this.search.currency,
        paymentMethod: String(this.search.payment_method),
        search_text: this.search.search_text,
        limit: 10,
      };
      await this.$store
        .dispatch("expenses/getList", data)
        .then((res) => {
          if (res.data.status == 1) {
            this.expenses_server_data_unfiltred = this.expenses_server_data =
              res.data.response.data;
            this.pagination = res.data.response;
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    getCurrencyName(id) {
      if ((this.currency || []).find((x) => x.id === id) != undefined) {
        return (this.currency || []).find((x) => x.id === id).symbol;
      }
      return false;
    },
    deleteData(expenseId) {
      this.showLoading();
      this.$store
        .dispatch("expenses/deleteExpense", expenseId)
        .then((res) => {
          //   console.log(res);
          this.expenses_server_data.splice(expenseId, 1);
          if (res.status == 1) {
            this.$toast.success("Delete Successful.");
            this.fetchCurrency();
            this.fetchPaymentMethod();
            this.expenseCategory();
            this.$nextTick(() => {
              this.getData();
            });
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    fileExpensesDetails(id) {
      this.file_data = this.$store.getters["expenses/getExpense"];

      const expense = this.file_data.filter((item) => item.id === id);
      const single_expense = expense[0];
      this.expenses_preview.id = single_expense.id;
      this.expenses_preview.expense_number = single_expense.expense_no;
      this.expenses_preview.category = this.getExpenseCategoryName(
        single_expense.category_id
      );
      this.expenses_preview.vendor = single_expense.vendor;
      this.expenses_preview.date = single_expense.expense_date;
      this.expenses_preview.payment_method = single_expense.payment_type;
      this.expenses_preview.note = single_expense.notes;
      this.expenses_preview.currency_id = single_expense.currency_id;
      this.expenses_preview.amount = single_expense.amount;
      this.expenses_preview.image_url = single_expense.url;
    },
    getExpenseCategoryName(category_id) {
      return this.expense_category.find((cat) => cat.id == category_id).category;
    },
    showEditData(id) {
      this.$router.push({ name: "edit-expense", params: { id: id } });
    },
    async generatePdf() {
      try {
        if (
          !this.search.date[0] ||
          !this.search.date[1] ||
          !this.search.category ||
          !this.search.currency
        ) {
          this.$toast.error("Date, category, and currency are required.");
          return;
        }

        this.showLoading();

        const data = {
          start_date: this.search.date[0],
          end_date: this.search.date[1],
          category_id: this.search.category,
          currency_id: this.search.currency,
        };

        const response = await this.$store.dispatch("expenses/generatePdf", data);
        // Assuming you have a file data as a Blob or Uint8Array
        const blob = new Blob([response.data], { type: "application/pdf" });

        // Create a URL for the Blob
        const pdfUrl = URL.createObjectURL(blob);

        // Open the PDF in a new window or tab
        const newTab = window.open(pdfUrl, "_blank");

        if (!newTab) {
          alert(
            "Your browser blocked the popup. Please allow popups for this site and try again."
          );
        }

        // Release the URL when no longer needed
        URL.revokeObjectURL(pdfUrl);
      } catch (error) {
        console.error(error);
        // this.$toast.error("Something went wrong. Please try again.");
      } finally {
        this.hideLoading();
      }
    },

    async generateZip() {
      if (
        !this.search.date[0] ||
        !this.search.date[1] ||
        !this.search.category ||
        !this.search.currency
      ) {
        this.$toast.error("Date, category and currency is required.");
        return false;
      }

      this.showLoading();
      const data = {
        start_date: this.search.date[0],
        end_date: this.search.date[1],
        category_id: this.search.category,
        currency_id: this.search.currency,
      };
      await this.$store
        .dispatch("expenses/generateZip", data)
        .then((res) => {
          const zipData = res; // Adjust this based on your API response structure

          const link = document.createElement("a");
          link.href = zipData;
          link.download = "smoothbalace_zip.zip"; // Set the desired download file name
          link.style.display = "none";
          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
        })
        .catch((err) => {
          console.log(err);
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    async generateExcel() {
      if (
        !this.search.date[0] ||
        !this.search.date[1] ||
        !this.search.category ||
        !this.search.currency
      ) {
        this.$toast.error("Date, category and currency is required.");
        return false;
      }

      this.showLoading();
      const data = {
        start_date: this.search.date[0],
        end_date: this.search.date[1],
        category_id: this.search.category,
        currency_id: this.search.currency,
      };
      await this.$store
        .dispatch("expenses/generateExcel", data)
        .then((res) => {
          const blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "smoothbalance_expense.xlsx");
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((err) => {
          console.log(err);
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    changeOrder() {
      if (this.expense_sorting_desc) {
        // Sort data in descending order
        this.expenses_server_data.sort(
          (a, b) => new Date(b.expense_date) - new Date(a.expense_date)
        );
      } else {
        // Sort data in ascending order
        this.expenses_server_data.sort(
          (a, b) => new Date(a.expense_date) - new Date(b.expense_date)
        );
      }
    },
  },
};
</script>

<style scoped>
.expenses-list-body .expenses-list-single {
  min-height: 150px;
}

.my-spinner {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
