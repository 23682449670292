export const API_URL = process.env.NODE_ENV === 'production'
    ? process.env.VUE_APP_API_URL
    : '/api/v3';
// export const API_URL = process.env.VUE_APP_API_BASE_URL + '/api/v3';
// export const API_URL = process.env.VUE_APP_BASE_API_URL;
export const API_URL_V2 = process.env.VUE_APP_BASE_API_URL_V2;

// user api
export const USER_LOGIN = API_URL + '/login';
export const USER_REGISTRATION = API_URL + '/register_user';
export const COUNTRIES_LIST = API_URL + '/get_new_country_list';
export const STATES_LIST = API_URL + '/get_states';
export const CURRENCY_LIST = API_URL + '/get_currency_list';
export const CURRENCY_LIST_BY_USER = API_URL + '/expense/filter/dropdown';
export const FORGOT_PASSWORD = API_URL + '/forgot_password';
export const UPDATE_PROFILE = API_URL + '/update_profile';
export const BUSINESS_CATEGORIES = API_URL_V2 + '/get_business_category';
export const PAYMENT_METHOD = API_URL + '/get_payment_options';
export const USER_UPDATE_COUNTRY = API_URL + '/update_country';
export const USER_UPDATE_LANGUAGE = API_URL + '/update_language';
export const USER_UPDATE_CURRENCY = API_URL_V2 + '/update_user_currency';

export const USER_GET_PROFILE = API_URL + '/get_profile';

// expenses
export const GET_EXPENSES = API_URL + '/pagination/get_expense_list';
export const SAVE_EXPENSES = API_URL + '/create_expense';
export const UPDATE_EXPENSES = API_URL + '/update_expense';
export const EXPENSE_CATEGORY = API_URL + '/expense/filter/dropdown';
export const ALL_EXPENSE_CATEGORY = API_URL + '/get_expense_category';
export const DELETE_EXPENSE = API_URL + '/delete_expense';
export const DELETE_EXPENSE_ATTACHMENT = API_URL + '/delete_expense_attachment';
export const GENERATE_PDF = API_URL + '/generate_pdf';
export const GENERATE_ZIP = API_URL + '/create_zip';
export const GET_EXPENSE_LIST_BY_ID = API_URL + '/getExpenseListById';
export const GENERATE_EXCEL = API_URL + '/export_expense';
export const GET_EXPENSES_LIMIT = API_URL + '/get_expense_count';

//client
export const GET_CLIENT = API_URL + '/get_clients';
export const CREATE_CLIENT = API_URL + '/create_client';
export const DELETE_CLIENT = API_URL + '/delete_client';

// items
export const GET_ITEMS = API_URL + '/get_item';
export const GENERATE_ITEM_NUMBER = API_URL + '/generate_item_no';
export const CREATE_ITEM = API_URL + '/create_item';
export const GET_CATEGORY_LIST = API_URL + '/list_item_category';
export const CREATE_CATEGORY = API_URL + '/create_item_category';
export const DELETE_CATEGORY = API_URL + '/delete_item_category';
export const DELETE_ITEM = API_URL + '/delete_item';
export const UPDATE_ITEM = API_URL + '/update_item';
export const UPDATE_ADDED_ITEM_DETAILS = API_URL + '/invoice/eachItemDetail';
export const UPDATE_ADDED_ITEM = API_URL + '/invoice/eachItemDetail/edit';

// quotation
export const GET_QUOTATION = API_URL + '/get_quotation_list_by_user';
export const GET_QUOTATION_NUMBER = API_URL + '/get_quotation_number';
export const SAVE_QUOTATION = API_URL + '/create_quotation';
export const SAVE_EDIT_QUOTATION = API_URL + '/update_quotation';
export const GET_QUOTATION_BY_NUMBER = API_URL + '/quotation/ListByquotationNumber';
export const DELETE_QUOTATION = API_URL + '/delete_quotation';
export const GET_QUOTATION_ITEM_DETAIL = API_URL + '/quotation/eachItemDetail';
export const UPDATE_ADDED_ITEM_QUOTATION = API_URL + '/quotation/eachItemDetail/edit';
export const SAVE_QUOTATION_ATTACHMENT = API_URL + '/upload_quotation_attachment';
export const SAVE_QUOTATION_SEARCH = API_URL + '/quotation/details/search';
export const SEND_EMAIL_QUOTATION = API_URL + '/quotation/emailSent';

// invoice
export const GET_INVOICE = API_URL + '/get_invoice_list_by_user';
export const GET_INVOICE_NUMBER = API_URL + '/get_invoice_number';
export const GET_INVOICE_BY_NUMBER = API_URL + '/invoice/ListByInvoiceNumber';
export const SAVE_INVOICE = API_URL + '/create_invoice';
export const SAVE_EDIT_INVOICE = API_URL + '/update_invoice';
export const GET_INVOICE_CLIENT_LIST = API_URL + '/get_clients';
export const GET_QUOTATION_CLIENT_LIST = API_URL + '/get_quotation_client_list';
export const DELETE_INVOICE = API_URL + '/delete_invoice';
export const VOID_INVOICE = API_URL + '/invoice/change_to_void';
export const GET_SEARCH_DATA = API_URL + '/invoice/details/search';
export const SAVE_INVOICE_ATTACHMENT = API_URL + '/upload_invoice_attachment';
export const SEND_EMAIL = API_URL + '/invoice/emailSent';

// setting
export const GET_SUBSCRIPTION = API_URL + '/subscription';
export const GET_TAX_DETAILS = API_URL + '/tax/get_details';
export const UPDATE_TAX = API_URL + '/tax/update';
export const GET_BANK_DETAILS = API_URL + '/bank/get_details';
export const UPDATE_BANK_DETAILS = API_URL + '/bank/update';
export const GET_SUBSCRIPTION_LIST = API_URL + '/web/payment/getPackages';
export const GET_SUBSCRIPTION_CHECKOUT = API_URL + '/web/payment/checkOut';
export const GET_SUBSCRIPTION_SUCCESS_HANDLER = API_URL + '/web/payment/responseHandler';