<template>
  <div>
    <iframe :src="pdfUrl" width="100%" height="600px" frameborder="0"></iframe>
  </div>
  <!-- Button to trigger email PDF -->
  <button class="btn btn-success btn-big _3d-input" @click="sendEmail">
    Email Quotation
  </button>
</template>

<script>
export default {
  name: "Addquotation",
  props: {
    title: String,
    // rest of the blog post props
  },
  data() {
    return {
      pdfUrl: "",
      quotation_data: "",
    };
  },
  created() {
    // Check on component creation if the item exists in local storage
    this.pdf_url_server();
  },
  computed: {
    pageTitle() {
      return (document.title = `${this.title.replace(/\b\w/g, (match) =>
        match.toUpperCase()
      )} | Smoothbalance`);
    },
  },
  methods: {
    sendEmail() {
      this.$store
        .dispatch("quotation/sendEmail", {
          quotation_number: this.quotation_data.quotation_number,
        })
        .then((res) => {
          // console.log(res);
          if (res.status == 1) {
            this.$toast.success("Email sent successfully");
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {});
    },
    pdf_url_server() {
      const retrievedItem = JSON.parse(localStorage.getItem("quotation-details"));

      if (retrievedItem) {
        this.pdfUrl = retrievedItem.pdfLink;
      }

      this.$store
        .dispatch("quotation/getQuotationByNumber", {
          quotation_number: retrievedItem.quotation_number,
        })
        .then((res) => {
          // console.log(res);
          if (res.status == 1) {
            this.quotation_data = {
              // quotation_status: 'draft',
              // quotation_type: 'draft',
              quotation_date: res.quotation_date,
              quotation_number: res.quotationNumber,
              client_id: res.client,
              validity_term: res.terms,
              currency_id: res.currency_id,
              payment: res.payment,
              notes: res.note,
              quotation_items: res.quotation_items,
            };
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {});
    },
  },
};
</script>
