<template>
  <div>
    <iframe :src="pdfUrl" width="100%" height="1000px" frameborder="0"></iframe>

    <!-- Button to trigger email PDF -->
    <button class="btn btn-success btn-big _3d-input" @click="sendEmail">
      Email Invoice
    </button>
  </div>
</template>

<script>
export default {
  name: "AddInvoice",
  props: {
    title: String,
    // rest of the blog post props
  },
  data() {
    return {
      pdfUrl: "",
      invoice_data: "",
    };
  },
  created() {
    // Check on component creation if the item exists in local storage
    this.pdf_url_server();
  },
  computed: {
    pageTitle() {
      return (document.title = `${this.title.replace(/\b\w/g, (match) =>
        match.toUpperCase()
      )} | Smoothbalance`);
    },
  },
  methods: {
    sendEmail() {
      this.$store
        .dispatch("invoice/sendEmail", {
          invoice_number: this.invoice_data.invoice_number,
        })
        .then((res) => {
          // console.log(res);
          if (res.status == 1) {
            this.$toast.success("Email sent successfully");
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {});
    },
    pdf_url_server() {
      const retrievedItem = JSON.parse(localStorage.getItem("invoice-details"));

      if (retrievedItem) {
        this.pdfUrl = retrievedItem.pdfLink;
      }

      this.$store
        .dispatch("invoice/getInvoiceByNumber", {
          invoice_number: retrievedItem.invoice_number,
        })
        .then((res) => {
          // console.log(res);
          if (res.status == 1) {
            this.invoice_data = {
              // invoice_status: 'draft',
              // invoice_type: 'draft',
              invoice_date: res.invoice_date,
              invoice_number: res.invoiceNumber,
              client_id: res.client,
              validity_term: res.terms,
              currency_id: res.currency_id,
              payment: res.payment,
              notes: res.note,
              invoice_items: res.invoice_items,
            };
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {});
    },
  },
};
</script>
