<template>
  <div class="">
    <div class="-content d-flex align-items-start flex-column">
      <div class="page-global-header px-0">
        <div class="row justify-content-end mx-0">
          <div
            class="col-sm-12 col-md-12 col-lg-6 col-xl-5 col-xxl-5 d-flex justify-content-end"
          >
            <button class="btn btn-white">Expense No: {{ form.expense_no }}</button>
          </div>
        </div>
      </div>

      <div class="page-body">
        <div class="row mx-0">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-5 col-xxl-5">
            <form class="base-form">
              <div class="row m-0 mb-3">
                <div class="input-group _group-xl p-0">
                  <span class="input-group-text prepend bg-bostonBlue flush-after">
                    Payment Method
                    <span class="required">*</span>
                  </span>
                  <select v-model="form.payment_type" required class="form-select">
                    <option disabled selected value="">Payment Method*</option>
                    <option
                      v-for="option in payment_method"
                      :value="option"
                      :key="option"
                    >
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row m-0 mb-3">
                <div class="input-group _group-xl p-0">
                  <span class="input-group-text prepend bg-bostonBlue flush-after">
                    Expense Date
                  </span>
                  <input
                    type="date"
                    name="datepick"
                    class="form-control datepicker"
                    v-model="form.expense_date"
                    onfocus="this.showPicker()"
                  />
                </div>
              </div>

              <div class="row m-0 mb-3">
                <div class="input-group _group-xl p-0">
                  <span class="input-group-text prepend bg-bostonBlue flush-after">
                    Currency
                  </span>
                  <select
                    v-model="form.currency_id"
                    required
                    class="form-select"
                    @change="changeCurrency"
                  >
                    <option disabled selected value="">Currency*</option>
                    <option
                      v-for="option in currencyOptions"
                      :value="option.id"
                      :key="option.id"
                    >
                      {{ option.name }} ({{ option.symbol }})
                    </option>
                  </select>
                  <!-- <select name="currency" class="form-select" required>
                                        <option value="" selected disabled>Please Select</option>
                                        <option value="">British Pound</option>
                                        <option value="">American Dollar</option>
                                        <option value="">Euro</option>
                                    </select> -->
                </div>
              </div>

              <div class="row m-0 mb-3 mt-5">
                <div class="input-group _group-xl p-0">
                  <select v-model="form.category_id" required class="form-select">
                    <option disabled selected value="">Expense category*</option>
                    <option
                      v-for="option in expenseCategoryServer"
                      :value="option.id"
                      :key="option.id"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row m-0 mb-3">
                <div class="input-group _group-xl p-0">
                  <input
                    type="text"
                    name="vendorName"
                    class="form-control"
                    placeholder="Name of the Vendor (Optional)"
                    v-model="form.vendor"
                  />
                </div>
              </div>

              <div class="row m-0 mb-4">
                <div class="input-group _group-xl h-auto p-0">
                  <textarea
                    name="notes"
                    class="form-control textarea-lg no-resize"
                    placeholder="Notes..."
                    v-model="form.notes"
                  ></textarea>
                </div>
                <span class="form-text text-end text-stamp">Max 255 character</span>
              </div>

              <div class="row m-0 mb-3 mt-5 d-flex justify-content-end">
                <div class="col-sm-9">
                  <div class="row">
                    <div class="input-group input-group--3d group-half p-0">
                      <div class="input-group-text bg-orange no-after prepend">
                        Amount Paid
                      </div>
                      <div class="form-floating">
                        <label class="floating-label">{{ currency_symbol }}</label>
                        <input
                          class="form-control text-right"
                          type="text"
                          v-model="form.amount"
                          placeholder="10,000.00"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 col-xxl-4 mx-auto">
            <div class="mb-2">
              <el-row>
                <el-col
                  v-for="(image_file_old, key_old) in image_list"
                  :key="key_old"
                  :span="12"
                >
                  <el-card :body-style="{ padding: '0px' }">
                    <a
                      href="javascript:"
                      data-bs-toggle="modal"
                      data-bs-target="#uploadPreview"
                      @click="selectImage(image_file_old)"
                    >
                      <img :src="image_file_old" class="img-fluid" />
                    </a>
                    <div style="padding: 14px">
                      <div class="bottom">
                        <el-button
                          type="danger"
                          @click="deleteAttachment(image_list_names[key_old])"
                          circle
                        >
                          <i class="fa fa-trash-alt"></i>
                        </el-button>
                      </div>
                    </div>
                  </el-card>
                </el-col>
              </el-row>
            </div>

            <el-carousel :interval="4000" v-if="image_list_edit.length > 0">
              <el-carousel-item
                v-for="(image_file, key) in image_list_edit"
                :key="'image_list_edit' + key"
              >
                  <img class="preview" :ref="'image' + parseInt(key)" />
              </el-carousel-item>
            </el-carousel>

            <div class="row" v-if="image_list_edit.length > 0">
              <div class="col-sm-12 d-flex align-items-center justify-content-center">
                <h6>Preview</h6>
              </div>
            </div>

            <div class="row mt-2rem">
              <div class="col-sm-12 d-flex align-items-center justify-content-center">
                <div class="custome-fileupload-circle">
                  <input
                    type="file"
                    class="file-input expense-file"
                    accept="image/jpeg, image/png"
                    multiple="multiple"
                    @change="onFileChange"
                    :max-size="512 * 1024"
                  />
                  <div class="file-content">
                    <div class="file-icon">
                      <i class="fa fa-camera"></i>
                    </div>
                    <div class="file-info-text">Add Attachment</div>
                  </div>
                </div>
              </div>
              <div
                class="col-sm-12 d-flex align-items-center justify-content-center mt-2"
              >
                <small>Note: Max 1 image, size: 256kb, png or jpeg only.</small>
              </div>
            </div>

            <div class="row mt-2rem">
              <div class="col-sm-12 d-flex align-items-center justify-content-between">
                <a href="/list-expenses" class="btn btn-default btn-shadow">Cancle</a>
                <button class="btn btn-success btn-shadow" @click="saveData">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--file-upload-preview messaging modal-->
    <div
      class="modal fade mdl-messaging _info"
      id="uploadPreview"
      aria-hidden="true"
      aria-labelledby="uploadPreviewLabel"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-dialog-header">
          <div class="header-title">Preview</div>
        </div>
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="upload-preview-frame">
              <div class="frame-header">
                <div class="col-auto">
                  <!-- <button
                    class="btn btn-transparent btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#zoopPreview"
                    data-bs-dismiss="modal"
                  >
                    <i class="fa fa-search"></i>
                  </button> -->
                </div>
                <div class="col-auto">
                  <!-- <button class="btn btn-transparent btn-sm">
                    <i class="fa fa-cloud-download-alt"></i>
                  </button> -->
                  <button
                    class="btn btn-transparent btn-sm btn-delet"
                    @click="deleteAttachment(image_list_names[key_old])"
                  >
                    <i class="fa fa-trash-alt"></i>
                  </button>
                </div>
              </div>

              <div class="frame-body">
                <div class="preview-screen">
                  <a
                    href="#"
                    class="preview-img"
                    data-bs-toggle="modal"
                    data-bs-target="#zoopPreview"
                    data-bs-dismiss="modal"
                    ><img :src="preview_image" />
                  </a>
                </div>
              </div>
            </div>

            <div class="upload-preview-thumb mt-4">
              <div
                class="thumb-item"
                v-for="(image_file_old, key_old) in image_list"
                :key="key_old"
              >
                <a href="#" class="thumb-image" @click="selectImage(image_file_old)"> <img :src="image_file_old" /></a>
                <span class="badge-count">1</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { CURRENCY_LIST } from "../../config/url";
import moment from "moment";

export default {
  name: "EditExpenseComponent",

  data() {
    return {
      loadingInstance: null,
      currency: null,
      payment_method: null,
      expense_category: null,
      form: {
        amount: "",
        expense_id: "",
        expense_no: "",
        payment_type: "",
        expense_date: "",
        category_id: "",
        vendor: "",
        notes: "",
        currency_id: "",
        image: [],
        is_image: "N",
      },
      image_list: [],
      image_list_edit: [],
      image_list_names: [],
      expense_data_state: {},
      image_url: [],
      image_count: 0,
      currency_symbol: "",
      preview_image: "",
    };
  },
  created() {
    this.runMountedFunctions();
  },
  computed: {
    currencyOptions() {
      if (this.currency) {
        return this.currency.map((v) => {
          return {
            id: v.id,
            name: v.name,
            symbol: v.symbol,
          };
        });
      }
      return [];
    },
    expenseCategoryServer() {
      if (this.expense_category) {
        return this.expense_category.map((v) => {
          return {
            id: v.id,
            name: v.name,
          };
        });
      }
      return [];
    },
  },
  methods: {
    runMountedFunctions() {
      this.fetchCurrency();
      this.fetchPaymentMethod();
      this.expenseCategory();
      this.$nextTick(() => {
        this.editDataPopulate(this.$route.params.id);
      });
    },
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
        fullscreen: true,
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    selectImage(imageLink) {
      this.preview_image = imageLink;
    },
    saveData() {
      this.showLoading();
      let expense_date_new = moment(this.expense_date).format("YYYY-MM-DD 00:00:00");

      let form_data = new FormData();

      this.image_list_edit.forEach((image) => {
        form_data.append("image[]", image);
        form_data.append("is_image", "Y");
      });

      form_data.append("expense_id", this.form.expense_id);
      form_data.append("expense_no", this.form.expense_no);
      form_data.append("amount", this.form.amount);
      form_data.append("payment_type", this.form.payment_type);
      form_data.append("expense_date", expense_date_new);
      form_data.append("category_id", this.form.category_id);
      form_data.append("vendor", this.form.vendor);
      form_data.append("notes", this.form.notes);
      form_data.append("currency_id", this.form.currency_id);
      

      this.$store
        .dispatch("expenses/updateExpenses", form_data)
        .then((res) => {
          if (res.status == 1) {
            this.$toast.success("Update data Successful.");
            this.$router.push({ name: "list-expenses" });
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    async fetchCurrency() {
      this.currency = await this.axios
        .get(CURRENCY_LIST)
        .then(function (res) {
          return res.data.response;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async expenseCategory() {
      this.showLoading();
      this.expense_category = await this.$store
        .dispatch("expenses/getAllExpenseCategory")
        .then((res) => {
          // console.log(res);
          if (res.status == 1) {
            return res.response;
            // this.$router.push({ name: 'user-dashboard' });
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    async fetchPaymentMethod() {
      this.showLoading();
      this.payment_method = await this.$store
        .dispatch("expenses/getPaymentOptions")
        .then((res) => {
          // console.log(res);
          if (res.status == 1) {
            return res.response;
            // this.$router.push({ name: 'user-dashboard' });
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    onFileChange(e) {
      // let vm = this;
      this.image_list_edit = new Array(...e.target.files);
      const files = e.target.files;
      const maxSize = 512 * 1024; // 512 KB in bytes

      for (let i = 0; i < this.image_list_edit.length; i++) {
        const file = files[i];
        if (file.size > maxSize) {
          this.$toast.error(`File "${file.name}" exceeds the maximum allowed size of 512 KB.`);
          // Optionally clear the input or handle the error in your application
          e.target.value = null;
          break;
        }

        let reader = new FileReader(); //instantiate a new file reader
        reader.addEventListener(
          "load",
          function () {
            this.$refs["image" + parseInt(i)][0].src = reader.result;
          }.bind(this),
          false
        ); //add event listener

        reader.readAsDataURL(this.image_list_edit[i]);
        if (this.image_list_edit.length > 0) {
          this.image_count = this.image_list_edit.length;
          this.form.is_image = "Y";
        }
      }

      this.image_list_edit = this.image_list_edit.filter(element => element.size <= (512 * 1024))
    },
    editDataPopulate(id) {
      this.$store
        .dispatch("expenses/getExpenseListById", { expenseId: id })
        .then((res) => {
          if (res.data.status == 1) {
            let single_expense = res.data.response;
            this.form.expense_id = id;
            this.form.expense_no = single_expense.expense_no;
            this.form.category_id = parseInt(single_expense.category_id);
            this.form.vendor = single_expense.vendor;

            this.form.expense_date = single_expense.expense_date;
            this.form.payment_type = single_expense.payment_type;
            this.form.notes = single_expense.notes;
            this.form.currency_id = single_expense.currency_id;

            this.form.amount = parseFloat(
              this.removeTrailingZeros(single_expense.amount)
            ).toFixed(2);

            // const parsed = JSON.parse(single_expense.url);
            if(single_expense.url !== "N/A" && single_expense.url !== null){
              this.image_list = single_expense.url;
              this.image_list_names = single_expense.file_name;
            }
            
            setTimeout(() => {
              this.changeCurrency();
            }, 1000);
          } else {
            throw new Error(res);
          }
          this.hideLoading();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    removeTrailingZeros(number) {
      // Convert the number to a string
      var numberString = number.toString();

      // Use a regular expression to remove trailing zeros
      var trimmedString = numberString.replace(/\.?0+$/, "");

      // Convert the string back to a number
      var result = parseFloat(trimmedString);

      return result;
    },
    deleteAttachment(image_name) {
      let form_data = new FormData();

      form_data.append("expense_no", this.form.expense_no);
      form_data.append("filename", image_name);
      this.$store
        .dispatch("expenses/deleteExpensesAttachment", form_data)
        .then((res) => {
          // console.log(res);
          if (res.status == 1) {
            this.$toast.success("Delete attachment Successful.");
            this.$router.push({
              name: "edit-expense",
              params: { id: this.form.expense_id },
            });
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    changeCurrency() {
      this.currency_symbol = this.currency.find(
        (currency) => currency.id == this.form.currency_id
      ).symbol;
    },
  },
};
</script>

<style scoped>
.image-container {
  position: relative;
}

.topright-image {
  box-sizing: content-box;
  position: absolute;
  top: 0.5em;
  right: -1.5em;
  line-height: 1;
}

.image-expense {
  width: 100%;
  height: auto;
}

.el-carousel {
  border: 2px solid #ccc;
  padding: 10px;
}

.preview {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border: 2px solid #fff;
}
</style>
