import {
  GET_QUOTATION,
  GET_QUOTATION_NUMBER,
  SAVE_QUOTATION,
  GET_QUOTATION_BY_NUMBER,
  DELETE_QUOTATION,
  SAVE_EDIT_QUOTATION,
  SAVE_QUOTATION_ATTACHMENT,
  SAVE_QUOTATION_SEARCH,
  SEND_EMAIL_QUOTATION
} from "../config/url";
import axios from "axios";
import authHeader from "./auth-header";

class QuotationService {
  getList(quotation_data) {
    return axios
      .post(GET_QUOTATION, quotation_data, {
        headers: authHeader(),
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }
  searchList(quotation_data) {
    return axios
      .post(SAVE_QUOTATION_SEARCH, quotation_data, {
        headers: authHeader(),
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }
  saveQuotation(quotation_data) {
    return axios
      .post(SAVE_QUOTATION, quotation_data, {
        headers: authHeader(),
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }
  saveQuotationAttachment(quotation_data) {
    return axios
      .post(SAVE_QUOTATION_ATTACHMENT, quotation_data, {
        headers: authHeader(),
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }
  saveEditQuotation(quotation_data) {
    return axios
      .post(SAVE_EDIT_QUOTATION, quotation_data, {
        headers: authHeader(),
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  getQuotationNumber() {
    return axios
      .get(GET_QUOTATION_NUMBER, {
        headers: authHeader(),
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  getQuotationByNumber(quotation_data) {
    return axios
      .post(GET_QUOTATION_BY_NUMBER, quotation_data, {
        headers: authHeader(),
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  }

  deleteQuotation(quotation_data) {
    return axios
      .post(DELETE_QUOTATION, quotation_data, {
        headers: authHeader(),
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  }

  sendEmail(quotation_number){
    return axios
      .post(SEND_EMAIL_QUOTATION, quotation_number, {
        headers: authHeader(),
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }
}

export default new QuotationService();
