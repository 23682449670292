import AuthService from '../services/auth.service';
import store from '../store'

const vuex = store;
const user = vuex?.auth;

const localStorageKey = 'auth_last_active_time';

const initialState = user
    ? { status: { loggedIn: true }, user, isProfileComplete: false, access_token: "", lastActiveTime: Date.now(), isProfileSubscriptionExpired: false, NoOFDaysFreeUsage: false }
    : { status: { loggedIn: false }, user: null, isProfileComplete: false, access_token: "", lastActiveTime: null, isProfileSubscriptionExpired: false, NoOFDaysFreeUsage: false };

export const auth = {
    namespaced: true,
    state: initialState,
    getters: {
        getLoggedIn: (state) => state.status.loggedIn,
        getUserData: (state) => state.user,
        getAccessToken: (state) => state.access_token,
        getProfileCompleteStatus: (state) => (state.user?.company_name !== "N/A" && state.user?.company_name !== null) && state.user?.companytype !== "N/A",
        getUserSubscriptionExpire: (state) => parseInt(state.user?.subscription_details?.noOfDaysRemaining) <= 0,
        getUserNoOFDays: (state) => parseInt(state.user?.no_of_days_of_free_usage) <= 0
    },
    actions: {
        login({ commit }, user) {
            return AuthService.login(user).then(
                user => {
                    commit('loginSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    AuthService.logout();
                    return Promise.reject(error);
                }
            );
        },
        storeTokenToState({ commit }, token) {
            commit('saveToken', token);
        },
        logout({ commit }) {
            AuthService.logout();
            commit('logout');
        },
        register({ commit }, user) {
            return AuthService.register(user).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },
        forgotPassword({ commit }, user) {
            return AuthService.forgotPassword(user).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },
        updateCurrency({ commit }, currency) {
            return AuthService.updateCurrency(currency).then(
                response => {
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        updateLanguage({ commit }, language) {
            return AuthService.updateLanguage(language).then(
                response => {
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        updateCountry({ commit }, country) {
            return AuthService.updateCountry(country).then(
                response => {
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        refreshProfile({ commit }) {
            return AuthService.getProfile().then(
                response => {
                    commit('refreshUserData', response.data);
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        }
    },
    mutations: {
        saveToken(state, token) {
            state.access_token = token;
        },
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user.response;
        },
        refreshUserData(state, user) {
            state.user = user.response;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;``
        },
        setCompanyDetails(state, { companyname, business_category_id }) {
            if (state.user) {
                state.user.companyname = companyname;
                state.user.companytype = business_category_id;
            }
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        }
    }
};
