<template>
  <div class="">
    <div class="
        dashboard-body-content
        d-flex
        justify-content-center
        align-items-center p-5
      ">
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-5 mx-auto">
        <!--login form block start-->
        <div class="card card-form card-addRecord">
          <div class="card-header">
            <div class="card-title">Add New Client</div>
          </div>
          <div class="card-body h-auto">
            <form @submit.prevent="handleSubmit" class="base-form">
              <h5 class="form-title">Client contact details</h5>
              <div class="mb-3">
                <input type="text" name="full_name" v-model="form.full_name" class="form-control"
                  placeholder="Full Name*" />
                  <span class="text-danger" v-if="errors.full_name">{{ errors.full_name }}</span>
              </div>

              <div class="mb-3">
                <input type="text" name="business_name" v-model="form.business_name" class="form-control"
                  placeholder="Business Name*" />
                  <span class="text-danger" v-if="errors.business_name">{{ errors.business_name }}</span>
              </div>
              <div class="mb-3">
                <input type="text" name="email" v-model="form.email" class="form-control" placeholder="Email*" />
                <span class="text-danger" v-if="errors.email">{{ errors.email }}</span>
              </div>

              <div class="mb-3">
                <input type="number" v-model="form.phone1" class="form-control" placeholder="Phone no" />
              </div>
              <!-- <div class="mb-3">
                <input type="number" v-model="form.phone2" class="form-control" placeholder="Mobile no" />
              </div> -->

              <!-- <div class="row d-flex align-items-center justify-content-center m-0 mb-2">
                <button type="button" name="toggleDrop" class="btn btn-toggleDrop" data-bs-toggle="collapse"
                  data-bs-target="#formMore" aria-expanded="false" aria-controls="formMore">
                  <span><i class="fas fa-chevron-down"></i> </span>
                  <span><i class="fas fa-chevron-down"></i> </span>
                </button>
              </div> -->

              <div class="" id="formMore">
                <div class="mb-3">
                  <input type="text" v-model="form.postal_address" class="form-control"
                    placeholder="Postal address / Street 1" />
                </div>

                <div class="mb-3">
                  <input type="text" v-model="form.street2" class="form-control" placeholder="Street 2" />
                </div>
                <div class="mb-3">
                  <select v-model="form.country" @change="fetchStateByCountry" class="form-select" placeholder="Country">
                    <option selected disabled>Country</option>
                    <option v-for="option in country_options" :value="option.name" :key="option.id">
                      {{ option.name }}
                    </option>
                  </select>
                </div>

                <div class="row g-3">
                  <div class="col-sm-12 col-md-12 col-lg-7 mb-3">
                    <select v-model="form.state" class="form-select" placeholder="Country">
                      <option selected disabled>State</option>
                      <option v-for="option in state_options" :value="option.name" :key="option.id">
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-5 mb-3">
                    <input type="text" v-model="form.zip" class="form-control" placeholder="Zip/Postal Code" />
                  </div>
                </div>

                <div class="mb-3">
                  <input type="text" v-model="form.notes" class="form-control" placeholder="Notes" />
                </div>
                <div class="mb-3">
                  <input type="text" v-model="form.website" class="form-control" placeholder="Website" />
                </div>
              </div>

              <div class="form-footer d-flex align-items-end mt-4">
                <button type="submit" role="button" :disabled="loading" class="btn btn-indigo">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
        <!--ended login form block-->
      </div>
    </div>
  </div>
</template>
<script>
import { COUNTRIES_LIST } from "../../config/url";

export default {
  name: "AddClient",
  data() {
    return {
      loadingInstance: null,
      loading: false,
      countries: null,
      states: null,
      business_categories: null,
      form: {
        full_name: "",
        email: '',
        business_name: "",
        phone1: "",
        phone2: "",
        country: "",
        postal_address: "",
        street1: "",
        state: "",
        zip: "",
        notes: "",
        website: "",
      },
      errors: {}
    };
  },

  computed: {
    pageTitle() {
      return document.title = `${this.title.replace(/\b\w/g, (match) =>
        match.toUpperCase()
      )} | Smoothbalance`;
    },
    country_options() {
      if (this.countries) {
        return this.countries.map((v) => {
          return {
            id: v.id,
            name: v.name,
          };
        });
      }
      return [];
    },
    state_options() {
      if (this.states) {
        return this.states.map((v) => {
          return {
            id: v.id,
            name: v.name,
          };
        });
      }
      return [];
    },
  },
  created() {
    this.runMountedFunctions();
  },
  methods: {
    runMountedFunctions() {
      this.fetchCountries();
    },
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: true
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    async fetchCountries() {
      this.countries = await this.axios
        .get(COUNTRIES_LIST)
        .then(function (res) {
          return res.data.response;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchStateByCountry() {
      this.showLoading();
      let country_id = this.countries.find((country) => country.name === this.form.country);

      this.$store
        .dispatch("client/fetchStateByCountry", { country_id: country_id.id })
        .then((res) => {
          if (res.status == 1) {
            this.states = res.response;
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    // Simple email validation using regex
    isValidEmail(email) {
      const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return re.test(email);
    },
    
    // Handle form submission with validation
    handleSubmit() {
      if (this.validateForm()) {
        this.createClient();
      }
    },
    validateForm() {
      this.errors = {};

      // Check full_name is not empty
      if (!this.form.full_name) {
        this.errors.full_name = "Full Name is required.";
      }

      // Check email is not empty and valid
      if (!this.form.email) {
        this.errors.email = "Email is required.";
      } else if (!this.isValidEmail(this.form.email)) {
        this.errors.email = "Please enter a valid email address.";
      }

      // Check business_name is not empty
      if (!this.form.business_name) {
        this.errors.business_name = "Business Name is required.";
      }

      // If there are no errors, return true (form is valid)
      return Object.keys(this.errors).length === 0;
    },
    createClient() {
      this.showLoading();
      this.$store
        .dispatch("client/createClient", this.form)
        .then((res) => {
          if (res.status == 1) {
            this.$toast.success("Create client Successful.");
            //this.$router.push({ name: 'list-clients' });
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  }
};
</script>