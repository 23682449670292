<template>
  <div class="">
    <div class="-content d-flex align-items-start flex-column">
      <div class="page-global-header">
        <div class="row">
          <div
            class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 offset-xl-3 offset-xxl-3"
          >
            <div class="global-searchbar">
              <form class="base-form">
                <div class="input-group">
                  <div class="input-group-text">
                    <img src="@/assets/images/system/groups.svg" />
                  </div>
                  <input
                    type="search"
                    name="globalSearch"
                    class="form-control"
                    placeholder="Search (Company name, Client name, Email etc)"
                  />
                  <button type="submit" name="btnSearch" class="btn btn-primary">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div
            class="col-sm-12 col-md-12 col-lg-12 col-xl-3 col-xxl-3 d-flex align-items-center justify-content-end"
          >
            <ul class="search-option-menu me-3">
              <li class="nav-item sorting">
                <input
                  type="checkbox"
                  class="sorting-check"
                  v-model="client_sorting_desc"
                  @change="changeOrder"
                  id="btn-sorting"
                  autocomplete="off"
                />
                <label class="btn btn-sorting" for="btn-sorting"
                  ><span class="sr-only">Sort</span>
                </label>
              </li>
              <li class="nav-item">
                <a href="/add-client" class="nav-link circleNav"
                  ><i class="fa fa-plus"></i>
                </a>
              </li>
              <li class="nav-item dropdown">
                <a
                  href="#"
                  class="nav-link dropdown-toggle no-caret"
                  id="optionMore"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  ><i class="fa fa-ellipsis-v"></i>
                </a>
                <ul
                  class="dropdown-menu dropdown-menu-end _v1"
                  aria-labelledby="optionMore"
                >
                  <li>
                    <a class="dropdown-item" href="#"
                      >Email CSV/PDF/Zip<i class="fa fa-envelope append"></i>
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#"
                      >Analytics <i class="fas fa-chart-line append"></i>
                    </a>
                  </li>

                  <li>
                    <a class="dropdown-item" href="#"
                      >Print <i class="fa fa-print append"></i
                    ></a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#"
                      >Download PDF/CSV <i class="fa fa-download append"></i>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="page-body">
        <div class="row justify-content-center">
          <!--repeatable block-->
          <div
            class="col-sm-7 mt-2"
            v-for="(client, index) in client_server_data"
            :key="index"
          >
            <!--repeatable block-->
            <div class="card card-flex-row">
              <div class="card-header">
                <i class="far fa-user"></i>
              </div>
              <div class="card-body">
                <ul class="cmn-vrList">
                  <li class="list-title">
                    <span class="card-count">1.</span>
                    <label>Co:</label>{{ client.business_name }}
                  </li>
                  <li class="sub-title"><label>N:</label>{{ client.full_name }}</li>
                  <li class="txt-alert"><label>E:</label>{{ client.email }}</li>
                  <li><label>T:</label>{{ client.phone1 }}</li>
                </ul>
              </div>
              <div class="card-footer">
                <ul class="card-action-nav">
                  <li class="nav-item">
                    <el-dropdown>
                      <span class="el-dropdown-link">
                        <i class="fas fa-ellipsis-v"></i>
                      </span>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <!-- <el-dropdown-item @click="showEditData(expense.id)"
                            ><i class="fa fa-edit"></i
                          ></el-dropdown-item> -->
                          <el-dropdown-item @click="deleteClient(client.id)"
                            ><i class="fa fa-times text-danger"></i
                          ></el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </li>
                  <li class="nav-item">
                    <a
                      href="#"
                      v-if="client.notes != 'N/A'"
                      class="btn btn-primary btn-sm"
                      data-bs-toggle="modal"
                      data-bs-target="#expenseNote"
                      @click="fileExpensesDetails(client.notes)"
                      ><i class="fa fa-edit"></i> Note
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <!--ended repeatable block-->
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade mdl-messaging _info"
      id="expenseNote"
      aria-hidden="true"
      aria-labelledby="expenseNoteLabel"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-dialog-header">
          <div class="header-title">Client Note</div>
        </div>
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-times"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <div class="message-card no-shadow m-0">
              <div class="card card--curve-05rem mt-3 w-100">
                <div class="card-body">
                  <p class="m-0">Note:</p>
                  <p class="m-0">
                    {{ note }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ListClient",
  data() {
    return {
      client_server_data: [],
      note: "",
      client_sorting_desc: "",
    };
  },
  computed: {},
  created() {
    this.runMountedFunctions();
  },
  methods: {
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
        fullscreen: true,
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    runMountedFunctions() {
      this.getData();
    },
    getData() {
      this.showLoading();
      this.$store
        .dispatch("client/getListByUser", this.form)
        .then((res) => {
          if (res.status == 1) {
            this.client_server_data = res.response;
            this.$toast.success("Fetch data Successful.");
            // this.$router.push({ name: 'user-dashboard' });
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.client_server_data = [];
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    deleteClient(client_id) {
      this.showLoading();
      this.$store
        .dispatch("client/deleteClient", { client_id: client_id })
        .then((res) => {
          if (res.status == 1) {
            this.$toast.success("Delete data Successful.");
            this.getData();
            // this.$router.push({ name: 'user-dashboard' });
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    fileExpensesDetails(note) {
      this.note = note;
    },
    changeOrder() {
      // Sort the client data based on business_name
      if (this.client_sorting_desc) {
        // Sort data in descending order
        this.client_server_data.sort((a, b) =>
          b.business_name.localeCompare(a.business_name)
        );
      } else {
        // Sort data in ascending order
        this.client_server_data.sort((a, b) =>
          a.business_name.localeCompare(b.business_name)
        );
      }
    },
  },
};
</script>
