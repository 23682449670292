<template>
  <div class="bg-pattern">
    <div class="-content d-flex align-items-start flex-column">
      <div class="page-global-header px-0">
        <div class="row mx-0">
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-5 col-xxl-5 mx-auto">
            <button class="btn btn-lauren-green btn-sm">
              <img src="@/assets/images/system/tools.svg" /> Add Item
            </button>
          </div>
          <div class="
              col-sm-12 col-md-12 col-lg-6 col-xl-5 col-xxl-5
              d-flex
              justify-content-end
              mx-auto
            "></div>
        </div>
      </div>

      <div class="page-body">
        <div class="row mx-0">
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-5 col-xxl-5 mx-auto">
            <AddItem></AddItem>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-5 col-xxl-5 mx-auto">
            <div class="card card-general h-100">
              <div class="card-count">2/2</div>
              <div class="card-body">
                <div class="row m-0 mb-3">
                  <div class="global-searchbar whiteBase _sm">
                    <form class="base-form d-flex flex-row">
                      <div class="d-flex flex-grow-1">
                        Recently added Items to the invoice
                      </div>
                      <button type="button" name="btn" class="btn btn-transparent">
                        <img src="@/assets/images/system/scroll-down.png" />
                      </button>
                    </form>
                  </div>
                </div>

                <form class="form-v2">
                  <div class="row m-0 mb-3">
                    <div class="contact-card-group mb-2" v-for="(added_items, index) in listOfAddedItems"
                      v-bind:key="'added-items' + index">
                      <!--repeatable block-->
                      <div class="card card-flex-row _v2">
                        <div class="card-header">
                          <span class="badge-count">{{ index + 1 }}</span>
                        </div>
                        <div class="card-body">
                          <ul class="cmn-vrList">
                            <li class="list-title">{{ added_items.name }}</li>

                            <li class="mt-2">
                              {{ added_items.description }}
                            </li>

                            <li class="price-tag">{{ added_items.quantity }}x &pound;{{ added_items.unit_price }}</li>
                          </ul>
                        </div>
                        <div class="
                            card-footer
                            justify-content-between
                            flex-column
                            align-items-end
                          ">
                          <ul class="hr-navList">
                            <li class="nav-item">
                              <a href="#" class="nav-link"><i class="fa fa-cog"></i>
                              </a>
                            </li>
                            <li class="nav-item ellipsis-dropdown dropdown">
                              <a href="#" class="nav-link dropdown-toggle no-caret" id="edit-act-1"
                                data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-ellipsis-v"></i></a>
                              <ul class="dropdown-menu" aria-labelledby="edit-act-1">
                                <li>
                                  <a class="dropdown-item edit" href="#"><i class="fa fa-edit"></i>
                                  </a>
                                </li>
                                <li>
                                  <a class="dropdown-item delete" href="#"
                                    @click="removeItemFromRecentList(added_items.item_no)"><i class="fa fa-times"></i>
                                  </a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                          <div class="ftr-price-tag">&pound;{{ added_items.quantity * added_items.unit_price }}</div>
                        </div>
                      </div>
                      <!--ended repeatable block-->
                    </div>
                  </div>

                  <div class="row m-0 mb-3 d-flex justify-content-end">
                    <div class="col-sm-9">
                      <div class="input-group group-half p-0">
                        <div class="input-group-text bg-orange no-after prepend">
                          Total Amount
                        </div>
                        <div class="form-floating">
                          <label class="floating-label">&pound;</label>
                          <input type="text" class="form-control text-right" placeholder="1" :value="calculateTotal" />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="card-footer">
                <div class="row m-0 d-flex justify-content-end">
                  <div class="cmn-aw float-start">
                    <router-link class="btn btn-white _3d-input" to="/item-list">
                      Cancel
                    </router-link>  
                  </div>
                  <div class="cmn-aw float-end">
                    <router-link class="btn btn-success btn-big _3d-input" to="/item-list">
                      Next
                    </router-link>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AddItem from "./AddItem.vue";

export default {
  name: "AddListItem",
  components: {
    AddItem
  },
  data() {
    return {
      loadingInstance: null,
      isLoading: false,
      loadingText: "Loading...",
      listAddedItemNumber: []
    }
  },
  created() {
    this.setAddedItemNumber();
    this.listAddedItemNumber = this.$store.getters['item/get_item_added_recently'];
  },
  computed: {
    calculateTotal() {
      // this.$store.commit('item/clearRecentItemsAddPage')
      const items = this.$store.getters['item/get_item_added_recently'] || [];
      // console.log(items)
      if (items.length == 0) {
        return 0.00;
      }
      const total = items.reduce((accumulator, item) => {
        return accumulator + (item.quantity * item.unit_price);
      }, 0);
      return total;
    },
    listOfAddedItems() {
      return this.listAddedItemNumber;
    }
  },
  beforeRouteLeave(to, from, next) {
    // Check if the route is changing
    if (to.path !== from.path) {
      // Call the mutation to clear the state
      this.$store.commit('item/clearRecentItemsAddPage');
    }
    next();
  },
  methods: {
    setAddedItemNumber() {
      this.listAddedItemNumber = this.$store.getters['item/get_item_added_recently'] || [];
    },
    removeItemFromRecentList(itemNoToRemove) {
      this.$store.commit('item/removeItemFromRecentList', itemNoToRemove);
      this.setAddedItemNumber();
    }
  }
};
</script>