import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from 'vue-axios';
import Toaster from '@meforma/vue-toaster';
import ElementPlus from 'element-plus';
// Import Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import { VueReCaptcha } from "vue-recaptcha-v3";
import "bootstrap";

import './assets/css/sb_masterLayout.css';
import 'element-plus/theme-chalk/index.css';
import './assets/css/style-new.css';

axios.interceptors.response.use((res) => {
    return res;
}, (error) => {
    if (error.response.status === 401) {
        // unauthorized.
        store.dispatch('auth/logout');
        router.push({ name: 'login' });
    }
    return Promise.reject(error);
});


// import * as mdb from 'mdb-ui-kit'; // lib
createApp(App)
    .use(VueAxios, axios)
    .use(router)
    .use(store)
    .use(Toaster)
    .use(ElementPlus)
    .use(VueReCaptcha, { siteKey: "6Lfy4A4pAAAAAHvlBbZ2tQdiKKCv6hON4b8QM1gL" })
    // .use(mdb)
    .mount("#app");